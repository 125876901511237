<template>
  <div class="map-tool">
    <div class="map-tool-left"></div>
    <div class="map-tool-right">
      <a-button v-if="params.showMapTrackDetailButton" @click="handleShowTrackDetail" class="map-tool-track-detail" size="small">轨迹明细</a-button>
      <template v-else>
        <a-select :value="timerValue" class="map-tool-item map-tool-timer" size="small" @change="handleChange">
          <a-select-option v-for="item in currentTimerOptions" :key="item.id" :value="item.id">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span class="map-tool-item">刷新</span>
      </template>
    </div>
    <div v-if="!params.showMapTrackDetailButton" class="map-tool-timer-msg">{{ currentCountDown }}秒后刷新</div>
  </div>
</template>
<script>
export default {
  name: 'Msg',
  props: {
    // 地图组件入参
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      timerValue: 20,
      currentCountDown: this.params.initCountDown,
      initCountDown: 0,
      currentTimerOptions: [
        {
          id: 20,
          label: '20s'
        },
        {
          id: 30,
          label: '30s'
        },
        {
          id: 40,
          label: '40s'
        },
        {
          id: 50,
          label: '50s'
        },
        {
          id: 60,
          label: '60s'
        }
      ]
    }
  },
  watch: {
    'params.showMapTrackDetailButton'(newVal, oldVal) {
      if (newVal) {
        this.unload()
      } else {
        // 重置刷新频率
        this.currentCountDown = this.initCountDown
        this.initTimer()
      }
    }
  },
  mounted() {
    this.initTimer()
  },
  methods: {
    initTimer() {
      // 如果父组件传入地图刷新频率，则使用传入，否则用默认值
      if (this.params.mapTool && this.params.mapTool.timer) {
        const timerValue = this.params.mapTool.timer.timerValue
        this.timerValue = timerValue
        const timerOptions = this.params.mapTool.timer.timerOptions
        if (timerOptions && timerOptions.length > 0) {
          this.currentTimerOptions = [...timerOptions]
        }
        if (timerValue) {
          this.currentCountDown = timerValue
        }
      }
      // 保存一份初始刷新频率
      if (this.initCountDown === 0) {
        this.initCountDown = this.currentCountDown
      }

      this.setCountDown(this.currentCountDown)
    },
    handleChange(value) {
      this.setCountDown(value)
    },
    // 显示轨迹播放明细
    handleShowTrackDetail() {
      this.$emit('mapToolClick', 'showMapTrackDetail', true)
    },
    setCountDown(totalCount) {
      this.timer && clearInterval(this.timer)
      this.currentCountDown = totalCount
      this.timer = setInterval(() => {
        this.currentCountDown--
        if (this.currentCountDown === 0) {
          this.$emit('mapToolClick', 'refreshData', true)
          this.currentCountDown = totalCount
        }
      }, 1000)
    },
    unload() {
      this.timer && clearInterval(this.timer)
    }
  },
  beforeDestroy() {
    this.unload()
  }
}
</script>
<style lang="less" scoped>
.map-tool {
  display: flex;
  height: auto;
  justify-content: space-between;
  background-color: @default-bg-color;
  align-items: center;
  .map-tool-right {
    margin-right: 5px;
  }
  .map-tool-item {
    margin-right: 5px;
  }
  .map-tool-timer {
    width: 60px;
    height: 25px !important;
    border-radius: 5px;
  }
  .map-tool-track-detail {
    margin-right: 5px;
    // height: 25px !important;
    border-radius: 5px;
  }
  .ant-select-selection {
    border-radius: 5px !important;
  }
  .map-tool-timer-msg {
    position: absolute;
    top: 37px;
    left: 130px;
    z-index: 2;
    border: 1px solid @borderColor;
    background-color: @default-bg-color;
    padding: 2px 5px;
  }
}
</style>
